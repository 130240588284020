import React from 'react'

interface CardToolProps {
    icon: string;
}
function CardTool(props: CardToolProps) {
    return (
        <div className="bg-neutral-200 dark:text-neutral-80 dark:bg-primary border-2 dark:border-primary/50 flex justify-center items-center text-sm rounded-lg h-12 w-full">
            {props.icon}
        </div>
    )
}

export default CardTool
