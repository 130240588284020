import {useState} from 'react'

interface CardProps {
    title: string;
    icon: string;
    text?: string;
}
function Card(props: CardProps) {
    const [isOpen, openAccordion] = useState(false)

    return (
        <div
            className={`${
                isOpen ? 'bg-white/50' : ''
            } col-span-12 px-6 py-4 shadow-lg bg-neutral-300 dark:bg-secondary flex items-center group hover:bg-white transition-all rounded-xl`}
        >
            <a
                href="#"
                onClick={(e) => {
                    e.preventDefault()
                    openAccordion(!isOpen)
                }}
                className="block w-full is-open"
            >
                <h2
                    className={`dark:text-neutral-800 text-base group-hover:text-light transition-all flex items-center`}
                >
                    {props.title}
                    <span
                        className={`${
                            isOpen ? 'text-light' : 'text-primary'
                        } bg-black group-hover:text-light ml-auto transition-all inline-flex p-2 rounded-full items-center material-symbols-outlined`}
                    >
                        {props.icon}
                    </span>
                </h2>
            </a>
            {isOpen ? (
                <>
                    <p
                        className={`${
                            isOpen ? 'text-neutral-400' : ''
                        } mt-4 text-sm group-hover:text-primary transition-all`}
                    >
                        {props.text}
                    </p>
                </>
            ) : undefined}
        </div>
    )
}

export default Card
