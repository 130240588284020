import AudioPlayer from './AudioPlayer'
import React, {useState} from 'react'
import useDarkSide from '../hooks/useDarkSide'
import {DarkModeSwitch} from 'react-toggle-dark-mode'

function Footer() {
    const [colorTheme, setTheme] = useDarkSide()
    const [darkSide, setDarkSide] = useState(colorTheme === 'light')

    const toggleDarkMode = (checked) => {
        setTheme(colorTheme)
        setDarkSide(checked)
    }

    return (
        <footer className="relative flex bg-neutral-100 filter-blur dark:bg-black shadow-xl dark:shadow-none rounded-xl px-4 mx-2 items-center h-14 mt-auto mb-4">
            <div className="inline-flex items-center">
                <ul className="list-social inline-flex items-center">
                    <li className="mr-4">
                        <a
                            className="dark:text-light text-lg inline-flex items-center"
                            target="_blank"
                            rel="noreferrer"
                            href="/files/CV2024-EN.pdf"
                        >
                            📄 <span className="text-sm ml-2">CV</span>
                        </a>
                    </li>
                    <li className="mr-4">
                        <a
                            className="uppercase dark:text-light text-sm"
                            target="_blank"
                            rel="noreferrer"
                            href="https://wa.me/3496629234"
                        >
                            📱
                        </a>
                    </li>
                    <li className="mr-4">
                        <a
                            className="uppercase dark:text-light text-2xl"
                            target="_blank"
                            rel="noreferrer"
                            href="mailto:giuliozulian@gmail.com"
                        >
                            ✉️
                        </a>
                    </li>
                    <li className="mr-4">
                        <a
                            className="uppercase dark:text-light text-sm"
                            target="_blank"
                            href="https://www.linkedin.com/in/giulio-zulian/"
                            rel="noreferrer"
                        >
                            Github
                        </a>
                    </li>
                    <li className="mr-4">
                        <a
                            className="uppercase dark:text-light text-sm"
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.linkedin.com/in/giulio-zulian/"
                        >
                            Linkedin
                        </a>
                    </li>
                </ul>
            </div>
            <AudioPlayer />

            <div className="dark:text-light ml-auto text-3xl material-symbols-outlined">
                <DarkModeSwitch
                    checked={darkSide}
                    onChange={toggleDarkMode}
                    size={30}
                />
            </div>
        </footer>
    )
}

export default Footer
