import React, {useEffect} from 'react'
import {gsap} from 'gsap'

function Loader() {
    useEffect(() => {
        setTimeout(() => {
            const loaderTimeline = gsap.timeline()

            loaderTimeline
                .to('#logo-loader', {
                    top: '32px',
                    ease: 'Expo.easeInOut',
                    scale: 0.225,
                    duration: 1.5,
                })
                .to(
                    '#laoding-bg',
                    {
                        height: 0,
                        pointerEvents: 'none',
                        ease: 'Expo.easeInOut',
                        duration: 1.25,
                    },
                    '<.25'
                )
        }, 300)
    }, [])

    return (
        <div className="loader pointer-events-none h-screen fixed top-0 left-0 w-screen flex items-center justify-center z-50">
            <span
                id="laoding-bg"
                className="bg-white absolute z-0 top-0 left-0 w-screen h-screen"
            ></span>
            <svg
                id="logo-loader"
                className="w-32 h-32 text-black absolute top-1/2 -translate-y-1/2"
                viewBox="0 0 119 119.398499"
                version="1.1"
                fill="black"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <defs>
                    <circle
                        id="path-1"
                        cx="98.5083876"
                        cy="15.7457326"
                        r="12.5"
                    ></circle>
                    <filter
                        x="-12.0%"
                        y="-12.0%"
                        width="124.0%"
                        height="124.0%"
                        filterUnits="objectBoundingBox"
                        id="filter-2"
                    >
                        <feMorphology
                            radius="2"
                            operator="erode"
                            in="SourceAlpha"
                            result="shadowSpreadInner1"
                        ></feMorphology>
                        <feGaussianBlur
                            stdDeviation="1"
                            in="shadowSpreadInner1"
                            result="shadowBlurInner1"
                        ></feGaussianBlur>
                        <feOffset
                            dx="2"
                            dy="2"
                            in="shadowBlurInner1"
                            result="shadowOffsetInner1"
                        ></feOffset>
                        <feComposite
                            in="shadowOffsetInner1"
                            in2="SourceAlpha"
                            operator="arithmetic"
                            k2="-1"
                            k3="1"
                            result="shadowInnerInner1"
                        ></feComposite>
                        <feColorMatrix
                            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.338805726 0"
                            type="matrix"
                            in="shadowInnerInner1"
                        ></feColorMatrix>
                    </filter>
                </defs>
                <g
                    id="Page-1"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    <g
                        id="Artboard"
                        transform="translate(-59.890410, -251.107463)"
                    >
                        <g
                            id="Group"
                            transform="translate(60.890410, 252.107463)"
                        >
                            <rect
                                id="Rectangle"
                                stroke="currentColor"
                                fill="currentColor"
                                strokeLinejoin="bevel"
                                x="-0.5"
                                y="41.8984993"
                                width="118"
                                height="34"
                                rx="17"
                            ></rect>
                            <rect
                                id="Rectangle"
                                stroke="currentColor"
                                fill="currentColor"
                                strokeLinejoin="bevel"
                                x="18.5"
                                y="83.8984993"
                                width="99"
                                height="34"
                                rx="17"
                            ></rect>
                            <rect
                                id="Rectangle"
                                stroke="currentColor"
                                fill="currentColor"
                                strokeLinejoin="bevel"
                                x="38.4264706"
                                y="-0.5"
                                width="79"
                                height="34"
                                rx="17"
                            ></rect>
                            <g id="Oval">
                                <use
                                    className="fill-amber-400"
                                    stroke="black"
                                    strokeWidth="2px"
                                    fillRule="evenodd"
                                    xlinkHref="#path-1"
                                ></use>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default React.memo(Loader)
